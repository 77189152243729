::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background: #fff4ed;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #ff864b;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ff864b;
  outline: 1px solid slategrey;
  border-radius: 5px;
}
body {
  overflow-x: hidden;
}
